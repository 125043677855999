<template>
  <div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-small-hide">
        <img
          class="banner-img"
          src="@/assets/img/slide.jpg"
          alt="Globe Check"
        />
      </div>
      <div
        class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
      >
        <md-card class="card-rounded p-2">
          <div class="d-flex justify-content-center align-item-center">
            <img
              class="reg-logo"
              src="@/assets/img/GlobeChek.png"
              alt="Globe Check Logo"
            />
            <p class="md-subheading m-0 ml-3 alert-head">
              {{ $route.params.userType }} Registration
            </p>
          </div>
          <div class="text-center pt-4">
            <p class="md-subheading text-alert">
              Thanks for registering with GlobeChek
            </p>

            <p class="alert-para1">
              You have completed the preliminary registration.
            </p>

            <fragment v-if="$route.params.userType != 'Doctor'">
              <p class="alert-para1 alert-para-sub">
                We will send a confirmation and login instructions to your
                email.
              </p>

              <p class="alert-para1 alert-para3">
                If you do not receive notification from GlobeChek please contact
                us at <span v-html="gcEmail" />
              </p>
            </fragment>
            <fragment v-else>
              <p class="alert-para1 alert-para3">
                If you have any queries please contact us at
                <span v-html="gcEmail" />
              </p>
            </fragment>
            <div class="button-container">
              <md-button class="md-primary" @click="finish()">OK</md-button>
            </div>
          </div>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      gcEmail: '<a href="mailto:Info@GlobeChek.com">Info@GlobeChek.com</a>',
    };
  },
  methods: {
    finish() {
      this.$router.push("/reading-center");
    },
  },
};
</script>

<style>
.alert-para1 {
  font-size: 13px;
  font-family: "Segoe UI", Helvetica Noue, sans-serif;
  font-weight: 500;
  line-height: 17px;
}
</style>
